<template>
    <div>
        <button-top v-if="permissions.u" icon="EditIcon" tooltip="Editar vivero" name="Editar" :date="form.created_at"
            @action="$router.push(`/gestion-viveros/update/${form.id}`)"></button-top>
        <b-row>
            <b-col cols="3" class="font-weight-bold">Nombre</b-col>
            <b-col cols="3">{{ form.name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Pais</b-col>
            <b-col cols="3">{{ form.country_name }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Departamento</b-col>
            <b-col cols="3">{{ form.department_name }}</b-col>
            <b-col cols="3" class="font-weight-bold">Municipio</b-col>
            <b-col cols="3">{{ form.city_name }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Latitud</b-col>
            <b-col cols="3">{{ form.latitude }}</b-col>
            <b-col cols="3" class="font-weight-bold">Longitud</b-col>
            <b-col cols="3">{{ form.longitude }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col cols="3" class="font-weight-bold">Capacidad</b-col>
            <b-col cols="3">{{ form.capacity }}</b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col cols="6" class="font-weight-bold">
                Observacion
            </b-col>

        </b-row>

        <b-row class="mt-1">
            <b-col cols="6">
                {{ form.observation }}
            </b-col>
        </b-row>
    </div>
</template>
<script>

export default {
    data() {
        return {
            form: {
                id: '',
                name: '',
                country_name: '',
                department_name: '',
                city_name: '',
                latitude: '',
                longitude: '',
                capacity: '',
                observation: '',
            },
        }
    },
    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.form.id = this.$route.params.id;
            await this.getPermission();
            await this.get();
        },

        async get() {
            try {
                this.loading();
                this.form = (await this.$http.get(`administration/nursery/show/${this.form.id}`)).data.data;
                this.setHeaderTitle(`Id. ${this.form.id} / ${this.form.name}`);
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push({ name: 'gestion-viveros' })
                }
                this.notify("Error", err.response.data.message, 'danger')
            } finally {
                this.not_loading();
            }
        },


    },


}
</script>
